import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function SocialMediaMarketingPage(props) {
    return (
        <div className="container draft-view">
           <h1>Social Media Marketing</h1>
           <p className="text-muted"></p>
           <p className="text-muted">When it comes to promoting a product or service on the internet, you have to take social networks into account. Social Media Marketing, or SMM, includes various methods of promotion on social networks, which are a very important source of attracting the target audience. It should be noted that website promotion on social networks is significantly different from other types of digital marketing, and therefore requires a professional approach.</p>
           <p className="text-muted">We will help you build a good reputation for your brand and good relationships with your target audience.</p>
           <p className="text-muted"></p>
           <h2>How promotion on social networks helps your business</h2>
           <p className="text-muted">One out of every three people in the world (from 7.7 billion people) and more than two-thirds of all internet users use social networks.</p>
           <p className="text-muted">The simple and personal communication of social networks gives you the opportunity to receive almost instant feedback from the target audience and promptly adjust your marketing actions.</p>
           <p className="text-muted">If clients complain about your business you can use social media to respond quickly and appropriately. The ability to do this on social networks can save the reputation of many companies.</p>
           <p className="text-muted">People expect customer service and support to be available on social media. You can profit from these expectations. Good customer service and support also make your new audience more likely to buy.</p>
           <p className="text-muted">Users of social networks are distinguished by higher brand loyalty, and the percentage of repeat purchases among them is much higher than users who come to your site from other channels.</p>
           <p className="text-muted">We help you profit from social media such as Facebook, Instagram, LinkedIn, and Twitter.</p>
           <p className="text-muted"><br /></p>
           <h2>Wescale social media marketing process</h2>
           <p className="text-muted"><br/></p>
           <h5>Research</h5>
           <p className="text-muted">At the start of our work, we receive briefings from you and discuss a strategy that will create a community that best meets the needs of the target audience. We study your brand, products, and services. We analyze competitors and reveal your advantages. We identify corporate, product, and service selling points and transform them into creative solutions.</p>
           <p className="text-muted"><br /></p>
           <h5>General plan</h5>
           <p className="text-muted">As a result, we prepare the main internal document of the company – the General plan, in which we determine the goal of the project, the main criteria for evaluating the work, the target audience, tools for attracting it, rubrics, and types of content that will best meet its needs.</p>
           <p className="text-muted"><br /></p>
           <h5>Content plan</h5>
           <p className="text-muted">After the approval of the Plan – the strategy for the implementation of the project, we begin to create a content plan. We make a content plan and approve it with you before publication.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">We work professionally on content. Content is divided into 5-7 categories and 4 types (official, public, cognitive, entertaining).</span></p>
           <p className="text-muted"><br /></p>
           <h5>Launch</h5>
           <p className="text-muted">After that, we launch social media campaigns, monitor results, and optimize. In the early stages, we support your service and sales assistants if they need to interact with customers through social media.</p>
           <p className="text-muted"><br /></p>
           <h5>Analysis</h5>
           <p className="text-muted">During campaigns, we create interim reports to optimize the campaign.&nbsp;<span style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">In the final stage, we create a detailed report with marketing data, analytics data, and our suggestions.</span></p>

        </div>

    );
}
